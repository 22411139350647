// Search.js
import React, { useState } from "react";
import { checkForToken } from "./AccessToken";
import { Card, Button } from "@mui/material";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import './Search.css';

// CLIENT_ID = '2d994768879d410c99b38b83cf1ab259';
// CLIENT_SECRET = 'cd9fdfdd767849f08ad6c7ebbeb5bc43';

const Search = () => {
    let accessToken;
    const [searchInput, setSearchInput] = useState('');
    const [albums, setAlbums] = useState([]);

    const shareButton = () => {
        console.log("Share functionality not implemented")
    }

    if (checkForToken()) {
        accessToken = window.localStorage.getItem("token");
    }

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setSearchInput(inputValue);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            search();
        }
    };

    async function search() {
        const searchParameters = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        const artistID = await fetch('https://api.spotify.com/v1/search?q=' + searchInput + '&type=artist', searchParameters)
            .then(response => response.json())
            .then(data => data.artists.items[0].id);

        const returnedAlbums = await fetch('https://api.spotify.com/v1/artists/' + artistID + '/albums' + '?include_groups=album&market=US&limit=50', searchParameters)
            .then(response => response.json())
            .then(data => setAlbums(data.items));
    }

    return (
        <div className="search-page-container">
            <div className="search-bar-container">
                <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 600 }}>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search SpotiPost"
                        inputProps={{ 'aria-label': 'search google maps' }}
                        onKeyDown={handleKeyPress}
                        onChange={handleInputChange}
                    />
                </Paper>
            </div>
           
            <div className="album-list">
                {albums.map((album, index) => (
                    <Card key={index} className="album-card">
                        <h1 className="album-title">{album.name}</h1>
                        <img src={album.images[1].url} alt={album.name} className="album-image" />
                        <Button variant="contained" className="share-button" onClick={shareButton}>Share</Button>
                    </Card>
                ))}
            </div>
        </div>
    );
}

export default Search;

let accessToken;

if (window.localStorage.getItem('token')) {
    accessToken = window.localStorage.getItem('token')
}

// Checks if token is stored in localstorage
export async function checkForToken() {
    if (accessToken) {
        return true;
    }
    return false;
}

export async function getToken() {
    if (checkForToken()) {
        return accessToken;
    } else {
        return console.log("Access token not found")
    }
}


// Fetches profile data
export async function getProfile() {
    if (checkForToken()) {
        try {
            const response = await fetch('https://api.spotify.com/v1/me', {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });
            if (response.ok) {
                const data = await response.json();
                return data;
            } else {
                throw new Error(`Failed to fetch profile data: ${response.status}`);
            }
        } catch (error) {
            console.error("Error while fetching profile data:", error);
        }
    } else {
        console.log("Token not found");
    }
}


// Fetches playlist data 
export async function getPlaylists() {
    if (checkForToken()) {
        try {
            const response = await fetch('https://api.spotify.com/v1/me/playlists', {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });
            if (response.ok) {
                const data = await response.json();
                return data;
            } else {
                throw new Error(`Failed to fetch profile data: ${response.status}`);
            }
        } catch (error) {
            console.error("Error while fetching profile data:", error);
        }
    } else {
        console.log("Token not found");
    }
} 



// Removes token from localstorage
export async function removeToken() {
    if (checkForToken()) {
        window.localStorage.setItem('token', '')
    } else {
        return;
    }
}
import React, { useEffect, useState } from "react";
import './Profile.css'
import { Card } from "@mui/material";
import { checkForToken, getPlaylists, getProfile } from "./AccessToken";

const Profile = () => {


    const [profileData, setProfileData] = useState([])
    const [playlistData, setPlaylistData] = useState([])


    // Fetches profile data and stores it in "profileData"
    useEffect(() => {
        async function fetchProfile() {
            if (checkForToken()) {
                const data = await getProfile();
                setProfileData(data);
            }
        }
        fetchProfile();
    }, []);


    // Fetches playlist data and stores it in "playlistData"
    useEffect(() => {
        async function fetchPlaylists() {
            if (checkForToken()) {
                const data = await getPlaylists();
                setPlaylistData(data.items);
            }
        }
        fetchPlaylists();
    }, []);




    // Displays playlists if they have been loaded
    // Otherwise displays "Loading playlists..."
    if (checkForToken()) {
        return (
            <div>
                <div className="sign-in-cont">
                    <h1 className="welcome-text">Welcome {profileData.display_name}</h1>
                </div>
                <h2>Your Spotify Playlists:</h2>
                {playlistData && playlistData.length > 0 ? (
                    <div className="playlist-grid">
                        {playlistData.map((playlist) => (
                            <div key={playlist.id} className="playlist-card">
                                <Card>
                                    {playlist.name}
                                    <img src={playlist.images[0].url} alt={playlist.name} width="300" height="300" />
                                </Card>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>Loading playlists...</p>
                )}
            </div>
        );
    }
}


export default Profile;



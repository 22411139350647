import React from "react";
import './Home.css';
import { Box, Card, CardContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import sampleData from '../sampleData/sampleData.json'

const Home = () => {

    const songs = sampleData.songs;
    const theme = useTheme();

    return (
        <div>
            
            <div>
                <div id="feedType">
                    <h3 className="feedOption">For you</h3>
                    <h3 className="feedOption" id="verticalLine">Friends are Listening to</h3>
                </div>
            </div>

            <Container className="feed" sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
            }}>

                {songs.map((song) => (
                    <Box className="feedItem"
                        sx={{
                            display: 'block',
                            margin: '15px',
                            marginBottom: '50px'
                        }}>

                        <Card className="song-card">
                            <CardContent>
                                <h3>{song.title}</h3>
                                <img className="song-image" src={song.image} alt="Song Image"></img>
                            </CardContent>
                            <Card>
                                <h4>{song.artist}</h4>
                            </Card>
                            <p>Posted by:</p>
                        </Card>
                    </Box>
                ))}
            </Container>

        </div >
    );
}

export default Home;

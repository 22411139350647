import './App.css';
import Navbar from './Components/Navbar';
import { useEffect } from "react";
import { Button, Card } from "@mui/material";


const scopes = ['user-read-email', 'user-read-private', 'playlist-read-private']
//Josh
const CLIENT_ID = '2d994768879d410c99b38b83cf1ab259'

//Max 
// const CLIENT_ID = 'dbbeef7cdb734134a6c56fb6189b29b9'


const REDIRECT_URI = 'https://spotipost.onrender.com/'
const AUTH_ENDPOINT = 'https://accounts.spotify.com/authorize'
const RESPONSE_TYPE = "token"

const AUTH_URL =
  `${AUTH_ENDPOINT}?
client_id=${CLIENT_ID}&
redirect_uri=${REDIRECT_URI}&
response_type=${RESPONSE_TYPE}&
scope=${scopes.join("%20")}`;

const App = () => {

  // Fetches token from URL
  useEffect(() => {
    const hash = window.location.hash
    let token = window.localStorage.getItem("token")
    if (!token && hash) {
      token = hash.substring(1).split('&').find(elem => elem.startsWith("access_token")).split("=")[1]
      window.location.hash = ""
      window.localStorage.setItem("token", token)
      window.location.reload();
    }
  }, [])




  // If token is added, display navbar component 
  // else display login button 
  let appContent;
  if (localStorage.getItem('token')) {

    appContent = <Navbar />

  } else {

    appContent = (
      <div className="App-body">
        <div className='background-image'>
        </div>
        <Card className='Login_card'>
          <h1>Welcome to SpotiPost! Please log in to continue</h1>
          <Button variant="contained" color="success" className='Login_button'
            href={AUTH_URL}>
            Login using Spotify
          </Button>
        </Card>
      </div>
    );
  }

  return (
    <div className="App">
      <div className="App-body">
        {appContent}
      </div>
    </div>
  );
}

export default App;

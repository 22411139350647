import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar'; // used for profile icon
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor'; // used for search icon
import { useState, useEffect } from "react";
import './Navbar.css';
import Home from "./Home";
import Profile from "./Profile";
import Search from "./Search";
import { getProfile, checkForToken, removeToken } from "./AccessToken";
import { Button } from "@mui/material";

const Navbar = () => {

    const [profileData, setProfileData] = useState([])

    // Fetches profile data and stores it in "profileData"
    useEffect(() => {
        async function fetchProfile() {
            if (checkForToken()) {
                const data = await getProfile();
                setProfileData(data);
            }
        }
        fetchProfile();
    }, []);


    // Logout functionality
    const logout = () => {
        removeToken();
        window.location.reload();
    }


    return (
        <Router>
            <nav className="header-nav">
                <ul>
                    <li key='search'>
                        <Link className="navLinks" to="/search">
                            <YoutubeSearchedForIcon sx={{ fontSize: 45 }} />
                        </Link>
                    </li>
                    <li key='home'>
                        <Link id="button-home" to="/">SpotiPost</Link>
                    </li>
                    {profileData.images && profileData.images.length > 0 ? (
                        <li key='profile' id="profile-clicks">
                            <Link className="navLinks" id="avatarLink" to="/profile">
                                <Avatar src={profileData.images[0].url} />
                            </Link>
                            <Button variant="contained" color="error" onClick={logout} >
                                Logout
                            </Button>
                        </li>
                    ) : (
                        <li key='profile'>
                            <Link className="navLinks" id="avatarLink" to="/profile">
                                <Avatar src="/broken-image.jpg" />
                            </Link>
                            <Button variant="contained" color="error" onClick={logout} >
                                Logout
                            </Button>
                        </li>
                    )}
                </ul>
            </nav>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/search" element={<Search />} />
            </Routes>
        </Router>
    );
}

export default Navbar;